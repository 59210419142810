<!-- 单个记录本列表项 -->
<template>
    <a-menu-item class="notebook-item" :key="item.noteBookId" @click="handleNoteBookClick(item)"
        style="padding: 0px;whiteSpace: normal;">
        <div :class="['normal-notebook', activeNoteid === item.noteBookId ? `active-notebook` : '']" @mouseover="mouseIn"
            @mouseleave="mouseOut">
            <svg-icon name="notebook" class="menu-item-icon"></svg-icon>
            <div class="exper-menu-item-text"> {{ item.noteBookName }} </div>
            <transition enter-active-class=" animate__fadeIn" leave-active-class=" animate__fadeOut">
                <!-- <div class="operation-ctn" v-show="isOperationShow" @click.stop="() => { }"> -->
                <div class="operation-ctn"  @click.stop="() => { }">
                    <a-popover trigger="click" :visible="isActive" @visibleChange="handleClickChange" placement="right">
                        <a-icon type="appstore" style="margin: auto;" @click.stop="() => { }" />
                        <template slot="content">
                           <!--  <div @click="handleDeleteClick" class="operation-item">
                                <a-icon type="delete" /> 删除
                            </div> -->
                            <div class="operation-item" @click="handleDetailClick">
                                <a-icon type="info-circle" /> 详情
                            </div>
                        </template>
                    </a-popover>
                </div>
            </transition>
        </div>
        <a-modal :width="680" v-model="detailModalVisible" :maskClosable="true" :footer="null" title="记录本详情">
            <a-spin :spinning="isDetailLoading" tip="正在加载">
                <a-form :labelCol="{ span: 8 }" :colon="true">
                    <a-row :gutter="24">
                        <a-col :span="12">
                            <a-form-item label="记录本名称">
                                <span class="col-content">
                                    {{  notebookDetail.noteBookName  }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="记录本编号">
                                <span class="col-content">
                                    {{  notebookDetail.notebookNo  }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="创建人">
                                <span class="col-content">
                                    {{  notebookDetail.createdUserName  }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="创建时间">
                                <span class="col-content">
                                    {{  notebookDetail.createdTime  }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-item label="所属项目" :labelCol="{ span: 4 }" style="margin-right: 24px;">
                                <span>
                                    <!-- {{  notebookDetail.projectName  }} -->
                                    <a-button type="link" @click="handleProjectClick">{{  notebookDetail.projectName  }} </a-button>
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :span="24">
                            <a-form-item :labelCol="{ span: 4 }" label="描述" style="margin-right: 24px;">
                                <span>
                                    {{  notebookDetail.description }}
                                </span>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </a-spin>
        </a-modal>
        <ProjectDetailModal :visible.sync="projectDetailModalVisible" :id="notebookDetail.projectId" :onlyPreview="true" :showJump="true">

        </ProjectDetailModal>
    </a-menu-item>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { computed, h, ref } from 'vue-demi';
import { cancelNoteBook, getNoteBookDetail } from '../../api/notebook';
import ProjectDetailModal from '../../views/itemCenter/components/projectDetailModal.vue';

export default {
    props: {
        activeNoteid: {
            default: -1
        },
        item: {
            default: {

            }
        },
        activeNoteid: {
            default: ''
        },
        index: {
            default: -1
        }
    },
    components: {
        ProjectDetailModal
    },
    setup(props, context) {
        const isHover = ref(false);
        const mouseIn = () => {
            isHover.value = true;
        }
        //const mouseOut = ref(false);
        const mouseOut = () => {
            isHover.value = false;
        }

        const isActive = ref(false);

        const isOperationShow = computed(() => {
            return isActive.value || isHover.value;
        })

        const handleClickChange = (visible) => {
            isActive.value = visible;
        }

        const handleNoteBookClick = (item) => {
            context.emit('onClick', item)
        }

        const handleDeleteClick = () => {
            isActive.value = false;
            Modal.confirm({
                //content: `是否确认删除记录本\r\n  ${props.item.noteBookName} ？`,
                /*  content: <div>
                     是否确认删除记录本 <span style="color: #1890ff;"></span>
                 </div>, */
                content: h('div', {}, [h('span', {}, '是否确认删除 '), h('span', { style: { color: '#1890ff' } }, `${props.item.noteBookName}`), ' ？']),
                deleteModalVisible: false,
                onOk: async () => {
                    /* await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve()
                        }, 3000)
                    }) */
                    const res = await cancelNoteBook({
                        noteBookId: props.item.noteBookId
                    });
                    if (res.success && (res.code === 200 || res.code === 204)) {
                        Modal.success({
                            content: `成功删除记录本  ${props.item.noteBookName} `
                        })
                        context.emit('onDelete')
                    }
                    else {
                        Modal.error({
                            content: res.message
                        })
                    }
                }
            })
        }

        // 详情对话框
        const detailModalVisible = ref(false);

        // 记录本关联的项目详情信息
        const projectInfo = ref({});

        // 详情是否正在加载
        const isDetailLoading = ref(true);

        // 记录本详情
        const notebookDetail = ref({})

        // 详情点击事件
        const handleDetailClick = async () => {
            isActive.value = false;
            detailModalVisible.value = true;
            //const res = 
            const res = await getNoteBookDetail({
                NoteBookId: props.item.noteBookId
            });
            if(res.code === 200 && res.success) {
                notebookDetail.value = res.data;
            }
            isDetailLoading.value = false;
        }

        // 所属项目详情对话框可见变量
        const projectDetailModalVisible = ref(false);
        // 所属项目点击事件
        const handleProjectClick = () => {
            projectDetailModalVisible.value = true;
        }

        return {
            isHover,
            mouseIn,
            mouseOut,
            isActive,
            isOperationShow,
            handleClickChange,
            handleNoteBookClick,
            handleDeleteClick,
            detailModalVisible,
            isDetailLoading,
            projectInfo,
            handleDetailClick,
            notebookDetail,
            projectDetailModalVisible,
            handleProjectClick
        }
    }
}
</script>


<style lang="less" scoped>
/deep/ .notebook-item.ant-menu-item-selected {
    background: @srims-primary-color !important;
    fill: white;
    color: #FFFFFF;
}

.normal-notebook {
    display: flex;
    padding: 8px 5px 8px 20px;
    position: relative;
    cursor: default;

    &:hover {
        background:  @srims-primary-color;
        color: white;
        fill: white;
    }

    transition: .3s all ease-in-out;

    .menu-item-icon {
        min-width: 16px;
        transform: translateY(4px);
    }

    .exper-menu-item-text {
        word-break: break-all;
        text-align: left;
        display: flex;
        white-space: wrap;
        display: block;
        line-height: 22px;
        margin-left: 8px;
        margin-right: 30px;
    }
}

.active-notebook {
    background:  @srims-primary-color;
    color: #ffffff;
    fill: #ffffff;
}

/deep/ .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

.ant-menu-item {
    background: transparent;


}

/deep/ .ant-menu-item-selected {
    color: rgba(0, 0, 0, 0.85);
    background-color: white !important;

    &:hover {
        background:  @srims-primary-color !important;
        color: white;
        fill: white;
    }

    &::after {
        border-right: none;
    }
}

/deep/ .ant-menu-submenu-selected {
    .exper-menu-header-text {
        color:  @srims-primary-color;
    }
}

/deep/ .ant-menu-submenu {
    .ant-menu-submenu-title {
        padding-left: 0px !important;
        padding-right: 0px !important;

        .ant-menu-submenu-arrow {
            right: 0px;
        }
    }

    &:hover {
        .exper-menu-header-text {
            color:  @srims-primary-color;
        }
    }
}

.operation-ctn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0%, -50%);
    //transform: translateY(-50%);
    cursor: pointer;
    //height: 100%;
    font-size: 20px;

    /deep/ .ant-popover-inner-content {
        padding: 0px;
    }
}

.operation-item {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background: #f7f7f7;
    }
}
</style>