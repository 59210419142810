<template>
	<div class="exper-manage-ctn">
		<div class="side-bar">
			<div
				:class="['side-bar-left', isLeftAsideCollapsed ? 'side-bar-left-collapsed animate__animated animate__backOutLeft' : 'side-bar-left-opened animate__animated animate__fadeInLeft']">
				<div class="search-panel">
					<a-input style="width: 220px;" placeholder="请输入记录本名称、编号名称" v-model="noteFilterStr" allowClear>
					</a-input>
					<a-button icon="search"></a-button>
				</div>
				<a-spin :spinning="leftLoading" tip="正在加载中..." :delay="300">
					<div v-show="!noteFilterStr" class="exper-menus">
						<a-menu style="width: 255px" mode="inline" :openKeys="openKeys">
							<a-sub-menu class="exper-menu" key="sub1" @titleClick="handleSubMenuTitleClick('sub1')">
								<template slot="title">
									<div class="exper-menu-header">
										<svg-icon name="open-record" class="exper-menu-header-icon"></svg-icon>
										<div class="exper-menu-header-text"> 打开记录</div>
									</div>
								</template>
								<a-menu-item v-for="(item, index) in recentExperimentList" :key="item.experimentId"
									@click.capture="handleExperMenuItemClick(item)" style="padding-left: 20px;" :title="item.title">
									<!-- <div class="exper-menu-item" style="height: fit-content;">
										<svg-icon name="test-tube" class="menu-item-icon"></svg-icon>
										<div class="exper-menu-item-text"> {{ item.title }} </div>
									</div> -->
									<svg-icon name="test-tube" class="menu-item-icon" style="transform: translateY(4px);"></svg-icon>
									{{ item.title }}
								</a-menu-item>
								<a-spin :spinning="subMenuLoading" tip="正在加载中">
									<a-empty v-if="!recentExperimentList.length" />
								</a-spin>
							</a-sub-menu>
							<a-sub-menu class="exper-menu" key="sub2" @titleClick="handleSubMenuTitleClick('sub2')">
								<template slot="title">
									<div class="exper-menu-header">
										<svg-icon name="authored-experiment" class="exper-menu-header-icon"></svg-icon>
										<div class="exper-menu-header-text"> 授权实验 </div>
									</div>
								</template>
								<a-menu-item v-for="(item, index) in authorizationExperimentList"
									:key="item.experimentId" @click.capture="handleExperMenuItemClick(item)"
									style="padding-left: 20px;" :title="item.title">
									<!-- <div class="exper-menu-item">
										<svg-icon name="test-tube" class="menu-item-icon"></svg-icon>
										<div class="exper-menu-item-text"> {{ item.title }} </div>
									</div> -->
									<svg-icon name="test-tube" class="menu-item-icon" style="transform: translateY(4px);"></svg-icon>
									{{ item.title }}
								</a-menu-item>
								<a-empty v-if="!authorizationExperimentList.length" />
							</a-sub-menu>
							<a-sub-menu class="exper-menu" key="sub3" @titleClick="handleSubMenuTitleClick('sub3')">
								<template slot="title">
									<div class="exper-menu-header">
										<svg-icon name="star" class="exper-menu-header-icon" style="fill: #FAAD14;">
										</svg-icon>
										<div class="exper-menu-header-text"> 我的收藏 </div>
									</div>
								</template>
								<a-menu-item v-for="(item, index) in collectionExperimentList" :key="item.experimentId"
									@click.capture="handleExperMenuItemClick(item)" style="padding-left: 20px;" :title="item.title">
									<!-- <div class="exper-menu-item">
										<svg-icon name="test-tube" class="menu-item-icon"></svg-icon>
										<div class="exper-menu-item-text"> {{ item.title }} </div>
									</div> -->
									<svg-icon name="test-tube" class="menu-item-icon" style="transform: translateY(3px);"></svg-icon>
									{{ item.title }}
								</a-menu-item>
								<a-empty v-if="!collectionExperimentList.length" />
							</a-sub-menu>
							<a-sub-menu class="exper-menu" key="sub4" @titleClick="handleSubMenuTitleClick('sub4')">
								<template slot="title">
									<div class="exper-menu-header">
										<svg-icon name="folder" class="exper-menu-header-icon"></svg-icon>
										<div class="exper-menu-header-text"> 全部 </div>
									</div>
								</template>
								<!-- <a-menu-item class="notebook-item" v-for="(item, index) in notebookList"
									:key="item.noteBookId" @click.capture="handleNoteBookClick(item)"
									style="padding: 0px;whiteSpace: normal; height: auto;">
									<div
										:class="['normal-notebook',activeNoteid === item.noteBookId ? `active-notebook` : '']">
										<svg-icon name="notebook" class="menu-item-icon"></svg-icon>
										<div class="exper-menu-item-text"> {{ item.noteBookName }} </div>
									</div>
								</a-menu-item> -->
								<NoteBookItem v-for="(item, index) in notebookList"
									:key="item.noteBookId" 
									@onClick="handleNoteBookClick"
									@onDelete="handleNoteBookDelete"
									style="padding: 0px; white-space: normal; height: auto;" class="notebook-item-ctn" :item="item" :activeNoteid="activeNoteid" :index="index">
								</NoteBookItem>
								<a-empty v-if="!notebookList.length" />
								<!-- <a-button style="margin-left: 12px; margin-top: 10px;" @click="addNoteBookModalVisible = true">
									+ 新增记录本
								</a-button> -->
							</a-sub-menu>
						</a-menu>
					</div>
					<div v-if="noteFilterStr" class="exper-menus">
						<div class="exper-menu">
							<div class="exper-menu-content">
								<a-menu mode="inline" :openKeys="['1']">
										<a-sub-menu key="1">
										<NoteBookItem v-for="(item, index) in notebookFilterList"
											:key="item.noteBookId" 
											@onClick="handleNoteBookClick"
											@onDelete="handleNoteBookDelete"
											style="padding: 0px; white-space: normal; height: auto;" class="notebook-item-ctn" :item="item" :activeNoteid="activeNoteid" :index="index">
										</NoteBookItem>
									</a-sub-menu>
								</a-menu>
							</div>
							<a-empty v-if="!notebookFilterList.length" />
						</div>
					</div>
				</a-spin>
			</div>
			<!-- 收起或展开条 start-->
			<a-tooltip arrow-point-at-center placement="right">
				<template slot="title">
					{{ isLeftAsideCollapsed ? '展开' : '收起' }}
				</template>
				<div class="collapse-bar" @click="toggoleCollapse">
					<div class="icon-container">
						<a-icon type="caret-left" class="collapse-icon" v-show="!isLeftAsideCollapsed" />
						<a-icon type="caret-right" class="collapse-icon" v-show="isLeftAsideCollapsed" />
					</div>
				</div>
			</a-tooltip>
			<!--收起或展开 end-->
		</div>
		<div class="exper-manage-main">
			<div class="exper-manage-main-content">
				<div class="exper-manage-main-content-header">
					实验列表
				</div>
				<div class="exper-manage-search-panel">
					<div class="search-option">
						<a-range-picker valueFormat="YYYY-MM-DD" v-model="dateRange" :disabled="!activeNoteid" />
					</div>
					<div class="search-option">
						<a-input placeholder="编号，名称，关键词" v-model="searchValue" :disabled="!activeNoteid" allowClear
							@pressEnter="handleSearch"></a-input>
					</div>
					<div class="search-option">
						<a-select allowClear v-model="conclusion" placeholder="请选择结论" :disabled="!activeNoteid">
							<a-select-option
								v-for="(item, index) in [{ label: '全部', value: 0 }, { label: '成功', value: 1 }, { label: '失败', value: 2 }, { label: '停止', value: 3 }]"
								:value="item.value" :key="index">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</div>
					<div class="search-option">
						<a-select allowClear v-model="experimentStatus" placeholder="请选择实验状态" :disabled="!activeNoteid">
							<a-select-option
								v-for="(item, index) in [{ label: '全部', value: 4 }, { label: '编辑', value: 1 }, { label: '申请归档', value: 2 }, { label: '归档成功', value: 3 }]"
								:value="item.value" :key="index">
								{{ item.label }}
							</a-select-option>
						</a-select>
					</div>
					<div class="search-operation">
						<a-button type="primary" class="search-btn" @click="handleSearch" :disabled="!activeNoteid"
							:style="!activeNoteid ? { background: '#eeeeee' } : { }">查询</a-button>
						<a-button class="reset-btn" @click="resetSearch" :disabled="!activeNoteid"> 重置 </a-button>
					</div>
				</div>
				<div class="exper-table-operations">
					<a-popover v-show="activeNoteid" placement="rightTop" trigger="click">
						<a-tooltip placement="top" title="展示列">
							<a-icon class="table-operation" type="unordered-list" />
						</a-tooltip>

						<template slot="content">
							<!-- <a-checkbox-group v-model="showColumns">
								<div v-for="(item, index) in allColumns" :key="index">
									<a-checkbox v-if="index < allColumns.length - 1" :disabled="item.disabled"
										:value="item">
										{{ item.title }}
									</a-checkbox>
								</div>
							</a-checkbox-group> -->
							<div v-for="(item, index) in showColumns" :key="index">
								<a-checkbox :disabled="item.disabled" :checked.sync="item.display"
									@change="(e) => handleColoumnsCheckedToggle(e, index)">
									{{ item.fieldName }}
								</a-checkbox>
							</div>
						</template>
					</a-popover>
					<!-- <a-tooltip placement="top" title="按编号排序">
						<svg-icon class="table-operation" name="interlining"></svg-icon>
					</a-tooltip> -->
					<a-button style="margin-left: 12px;" @click="addExperimentModalVisible = true">
						新增实验
					</a-button>
					<a-button style="margin-left: 12px; " @click="addNoteBookModalVisible = true">
						+ 新增记录本
					</a-button>
					<!-- <a-button style="margin-left: 12px;" @click.prevent="handleTest">
						测试
					</a-button> -->

					<!-- bug 必须在table外存在过才能展示 -->
					<svg-icon name="report" style="display: none;"></svg-icon>
					<svg-icon name="history" style="display: none;"></svg-icon>
					<svg-icon name="author" style="display: none;"></svg-icon>
					<svg-icon name="star" style="display: none;"></svg-icon>
				</div>
				<div v-if="activeNoteid" class="table-ctn">
					<a-table rowKey="id" :custom-row="customRow" :columns="tableColumns" :data-source="list"
						:pagination="false" size="small" class="exper-table" :scroll="{ y: tableHeight, x: 1200 }"
						:loading="tableLoading">
						<template slot="experimentNo" slot-scope="text, record, index">
							<a :class="[openExperimentList.indexOf(record.id) !== -1 ? 'open-link' : '']"
								@click="handleExperimentClick(record)">
								{{ record.experimentNo }}
							</a>
						</template>
						<template slot="conclusion" slot-scope="text, record, index">
							<div :style="{ color: getConclusionColors(record.conclusion) }">
								{{ getConclusionText(record.conclusion) }}
							</div>
						</template>
						<template slot="experimentStatus" slot-scope="text, record, index">
							<a-tooltip v-if="getExperimentStatuText(record.experimentStatus)" title="查看流程状态"
								@click="handleStatusFlowClick(record.id)">
								<!-- <div class="statu-btn"
									:style="{ background: getExperimentFlowStatuColor(record.experimentStatus), padding: `5px 8px`, 'text-align': 'center', width: '72px', 'border-radius': '4px', color: '#ffffff', cursor: 'pointer' }">
									{{ getExperimentStatuText(record.experimentStatus) }}
								</div> -->
								<a-tag :color="getExperimentFlowStatuColor(record.experimentStatus)">
									{{ getExperimentStatuText(record.experimentStatus) }}
								</a-tag>
							</a-tooltip>
							<div v-else>
								————
							</div>
						</template>
						<template slot="operation" slot-scope="text, record, index">
							<div class="operations">
								<a-tooltip title="克隆" @click="handleExperimentCloneButtonClick(record)">
									<svg-icon name="copy" class="icon1"></svg-icon>
								</a-tooltip>
								<a-tooltip title="生成实验报告" @click="handleMakeReportButtonClick(record)">
									<svg-icon name="report" class="icon1"></svg-icon>
								</a-tooltip>
								<a-tooltip title="操作记录或版本记录" @click="goToExperimentRecord(record)">
									<svg-icon name="history" class="icon1"></svg-icon>
								</a-tooltip>
								<a-tooltip title="授权">
									<svg-icon name="author" class="icon1" @click="handleAuthorizeButtonClick(record)">
									</svg-icon>
								</a-tooltip>
								<a-tooltip v-if="!record.isCollection" title="点击收藏">
									<svg-icon name="star" class="icon1" @click="handleCollectionClick(record)">
									</svg-icon>
								</a-tooltip>
								<a-tooltip v-else title="取消收藏">
									<svg-icon name="star" style="fill:#FAAD14; " class="icon1"
										@click="handleCollectionCancelClick(record)"></svg-icon>
								</a-tooltip>
								<!-- <a-tooltip
								v-if="record.experimentStatus === 1" 
								 title="删除" @click="handleExperimentDeleteClick(record)">
									<a-icon type="delete" theme="filled"  class="icon1"/>
								</a-tooltip> -->
							</div>
						</template>
					</a-table>
				</div>
				<a-result v-if="!activeNoteid && !leftLoading" status="404" title="未选择记录本" sub-title="请先选择/新建一个记录本"
					style="margin-top: 80px;">
				</a-result>
				<div class="default-pagination">
					<a-pagination :show-total="total => `共 ${total} 条`" size="small" hideOnSinglePage @change="handlePageChange" v-model="current"
						:pageSize="10" :total="totalRows" show-less-items />
				</div>
			</div>
		</div>
		<AuthorizeModal :visible.sync="authorizeModalVisible" :ExperimentId="authoringExperiment.id"
			@onAhtorization="onAhtorization"></AuthorizeModal>
		<MakeReprotModal v-if="makeReportModalVisible" :visible.sync="makeReportModalVisible" :Id="makeReportId"></MakeReprotModal>
		<ExperimentCloneModal :visible.sync="experimentCloneModalVisible" :ExperimentId="cloneExperimentId"
			@onClone="onClone"></ExperimentCloneModal>
		<AddNewNotebookModal :visible.sync="addNoteBookModalVisible"></AddNewNotebookModal>
		<AddNewExperimentModel :visible.sync="addExperimentModalVisible" :noteBookId="activeNoteid">
		</AddNewExperimentModel>
		<StatusFlowDrawer :visible.sync="statusFlowDrawerVisible" :experimentId="statusFlowExperimentId">
		</StatusFlowDrawer>
	</div>
</template>

<script>
import { computed, h, onMounted, ref, watch } from '@vue/composition-api';
import AuthorizeModal from '../../components/authorizeModal.vue';
import MakeReprotModal from '../../components/experimentManage/makeReportFromServer.vue';
import ExperimentCloneModal from '../../components/experimentManage/experimentCloneModal.vue';
import AddNewNotebookModal from '../../components/experimentManage/addNewNotebookModal.vue';
const tableHeight = document.documentElement.clientHeight - 360;
import { getNoteBookList } from '../../api/notebook';
import { getExperimentList, getRecentExperimentHistoryList, getAuthorizationExperiment, getAntEditExperiment, getCollectionExperimentList, getFieldList, setFieldList, addMyCollection, cancleMyCollection, cancelExperiment } from '../../api/experiment';
import AddNewExperimentModel from '../../components/experimentManage/addNewExperimentModel.vue';
import { message, Modal } from 'ant-design-vue';
import StatusFlowDrawer from '../itemCenter/components/statusFlowDrawer.vue';
import { useDocumentVisibility, useTextSelection } from '@vueuse/core';
import { getExperimentFlowStatuColor } from '../../utils/enums';
import { getExperimentContextMenu } from '../../utils/experimentContextMenu'
import NoteBookItem from '../../components/experimentManage/noteBookItem.vue';

export default {
	props: [],
	setup(props, context) {
		const isLeftAsideCollapsed = ref(false);
		const toggoleCollapse = () => {
			isLeftAsideCollapsed.value = !isLeftAsideCollapsed.value;
		};
		// 全部的列
		const allColumns = ref([
			{
				title: "实验编号",
				dataIndex: "experimentNo",
				disabled: true,
				scopedSlots: { customRender: "experimentNo" }
			},
			{
				"id": 302667397578822,
				title: "实验名称",
				dataIndex: "title"
			},
			{
				"id": 305135203418181,
				title: "记录本名称",
				dataIndex: "noteBookName"
			},
			{
				"id": 302667397578823,
				title: "关键词",
				dataIndex: "keyWord"
			},
			{
				"id": 302667397578824,
				title: '室温',
				dataIndex: 'roomTemperature'
			},
			{
				"id": 302667397578825,
				title: '相对湿度',
				dataIndex: 'relativeHumidity'
			},
			{
				"id": 302667397578828,
				title: "创建人",
				dataIndex: "createdUserName"
			},
			{
				"id": 302667397578829,
				title: "创建时间",
				dataIndex: "createdTime"
			},
			{
				"id": 302667397578830,
				title: "最后修改人",
				dataIndex: "updatedUserName"
			},
			{
				"id": 302667397578826,
				title: "结论",
				dataIndex: "conclusionName"
			},
			{
				"id": 302667397578827,
				title: "状态",
				dataIndex: "experimentStatusName"
			},
			{
				title: "操作",
				dataIndex: "operation",
				width: 210,
				fixed: "right",
				scopedSlots: { customRender: "operation" }
			}
		]);
		// 存储的列数据
		const showColumns = ref([
			{
				"id": 302667397578821,
				"fieldName": "实验编号",
				"display": true
			},
			{
				"id": 302667397578822,
				"fieldName": "实验名称",
				"display": true
			},
			{
				"id": 302667397578823,
				"fieldName": "关键词",
				"display": true
			},
			{
				"id": 302667397578824,
				"fieldName": "室温",
				"display": true
			},
			{
				"id": 302667397578825,
				"fieldName": "相对湿度",
				"display": true
			},
			{
				"id": 302667397578826,
				"fieldName": "实验结论",
				"display": true
			},
			{
				"id": 302667397578827,
				"fieldName": "实验状态",
				"display": true
			},
			{
				"id": 302667397578828,
				"fieldName": "创建人",
				"display": true
			},
			{
				"id": 302667397578829,
				"fieldName": "创建时间",
				"display": true
			},
			{
				"id": 302667397578830,
				"fieldName": "最后修改人",
				"display": true
			},
			{
				"id": 302667397578831,
				"fieldName": "修改时间",
				"display": true
			},
			{
				title: "操作",
				"id": 302667397578832,
				"fieldName": "操作",
				"display": true
			}
		]);
		// 展示列选项点击事件
		const handleColoumnsCheckedToggle = async (e, index) => {
			const newTemp = JSON.parse(JSON.stringify(showColumns.value[index]));
			newTemp.display = e.target.checked;
			showColumns.value.splice(index, 1, newTemp);
			await setFieldList({ FieldId: newTemp.id, Display: newTemp.display });
		}
		// 实际展示的列数据
		const tableColumns = computed(() => {
			const list = [{
				title: "实验编号",
				dataIndex: "experimentNo",
				disabled: true,
				scopedSlots: { customRender: "experimentNo" }
			}];
			showColumns.value.forEach(element => {
				if (element.field === 'ExperimentNo') {
					return
				}
				if (element.display) {
					list.push({
						title: element.fieldName,
						id: element.id,
						dataIndex: element.field,
						scopedSlots: { customRender: element.field }
					})
				}

			})
			list.push({
				title: "操作",
				"fieldName": "操作",
				dataIndex: "operation",
				width: 210,
				fixed: "right",
				scopedSlots: { customRender: "operation" }
			})
			return list;
		});
		// 左侧展开submenu
		const openKeys = ref([`sub4`])
		// 展开目录加载状态
		const subMenuLoading = ref(false);
		// 左侧展开目录点击事件
		const handleSubMenuTitleClick = async (key) => {
			const index = openKeys.value.indexOf(key);
			if (index === -1) {
				subMenuLoading.value = true;
				openKeys.value = [key];
				switch(key) {
					case 'sub1':
						// 获取最近打开的实验
						recentExperimentList.value = [];
						const recentRes = await getRecentExperimentHistoryList();
						if (recentRes.code === 200) {
							recentExperimentList.value = recentRes.data;
						}
						else if (recentRes.code === 401) {
							return;
						}
						break;
					case 'sub2': 
						// 获取授权的实验
						authorizationExperimentList.value = [];
						const authorRes = await getAuthorizationExperiment();
						if (authorRes.code === 200) {
							authorizationExperimentList.value = authorRes.data;
						}
						break;
					case 'sub3': 
						// 获取我的收藏
						collectionExperimentList.value = [];
						await getCollectionList();
						break;
				}
				subMenuLoading.value = false;
			}
			else {
				openKeys.value.splice(0, openKeys.value.length);
			}
		}

		const getExperimentHref = (record) => {
			const mode = record.experimentStatus === 1 ? 'edit' : 'preview';
			const href = context.root.$router.resolve({ name: 'experiment', query: { mode: mode, id: record.id } });
			return href.href;
		}
		/******************           授权对话框  start           *******************/
		const authorizeModalVisible = ref(false);
		// 授权点击
		const handleAuthorizeButtonClick = (item) => {
			authoringExperiment.value = item;
			authorizeModalVisible.value = true;
		}
		/******************           授权对话框  end             *******************/
		// 操作记录点击
		const goToExperimentRecord = (record) => {
			const url = context.root.$router.resolve({
				name: 'experimentRecord',
				query: {
					tab: 1,
					id: record.id
				}
			});
			window.open(url.href, '_blank');
		}
		/******************          生成实验报告对话框 start           *******************/
		// 生成实验报告对话框
		const makeReportModalVisible = ref(false);
		// 生成实验报告的实验的id
		const makeReportId = ref(null);
		// 生成实验报告按钮点击事件
		const handleMakeReportButtonClick = (record) => {
			makeReportId.value = record.id;
			makeReportModalVisible.value = true;
		}
		/******************          生成实验报告对话框  end            *******************/
		/******************           克隆实验对话框  start             ******************/
		// 克隆实验对话框可见变量
		const experimentCloneModalVisible = ref(false);
		// 克隆选中实验id
		const cloneExperimentId = ref(null);
		// 克隆实验按钮点击事件
		const handleExperimentCloneButtonClick = (record) => {
			cloneExperimentId.value = record.id;
			experimentCloneModalVisible.value = true;
		}
		// 克隆实验回调
		const onClone = (noteBookId) => {
			/* activeNoteid.value = noteBookId
			current.value = 1; */
			searchExperiment();
		}
		/******************           克隆实验对话框   end              ******************/
		/******************            新建记录本   start               ******************/
		// 新增记录本
		const addNoteBookModalVisible = ref(false);
		watch(() => context.root.$store.state.trigger.addNoteBookTrigger, async () => {
			// 获取记录本列表
			const noteRes = await getNoteBookList();
			if (noteRes.code === 200) {
				notebookList.value = noteRes.data;
				activeNoteid.value = notebookList.value.length > 0 ? notebookList.value[0].noteBookId : '';
				current.value = 1;
				resetSearch();
				searchExperiment()
				//activeNoteid.value = notebookList.value.length > 0 ? notebookList.value[0].noteBookId : null;
			}
		})
		/******************             新建记录本  end                 ******************/
		/******************              新增实验 start                 ******************/
		const addExperimentModalVisible = ref(false);
		watch(() => context.root.$store.state.trigger.addExperimentTrigger, async () => {
			searchExperiment();
		})
		/******************              新增实验 end                   ******************/
		/******************             记录本列表 start                ******************/
		// 记录本列表
		const notebookList = ref([]);
		// 活跃的记录本id
		const activeNoteid = ref(null);
		/******************             记录本列表  end                 ******************/

		/******************               实验列表 start                ******************/
		// 当前页数
		const current = ref(1);
		// 页面总数
		const totalRows = ref(0);
		// 列表加载
		const tableLoading = ref(false);
		// 页面更改
		const handlePageChange = (page, pageSize) => {
			searchExperiment();
			return;
		};
		// 实验列表
		const list = ref([]);
		// 已经打开的列表
		const openExperimentList = ref([])
		// 搜索值
		const searchValue = ref('');
		// 日期范围
		const dateRange = ref([]);
		// 结论值,0全部，1成功，2失败，3停止
		const conclusion = ref(undefined)
		// 实验状态 0编辑、1申请归档、2归档成功
		const experimentStatus = ref(undefined);
		// 最近打开的试验记录列表
		const recentExperimentList = ref([]);
		// 被授权的实验列表
		const authorizationExperimentList = ref([]);
		// 授权的实验列表
		const collectionExperimentList = ref([]);
		// 获取收藏的实验列表
		const getCollectionList = async () => {
			const res = await getCollectionExperimentList();
			if (res.code === 200) {
				collectionExperimentList.value = res.data
			}
		}
		// 获取实验列表
		const searchExperiment = async () => {
			tableLoading.value = true;
			const experRes = await getExperimentList({
				"pageNo": current.value,
				"pageSize": 10,
				"searchBeginTime": dateRange.value.length === 2 ? dateRange.value[0] : '',
				"searchEndTime": dateRange.value.length === 2 ? dateRange.value[1] : '',
				"noteBookId": activeNoteid.value,
				"searchValue": searchValue.value,
				"conclusion": conclusion.value === undefined ? 0 : conclusion.value,
				"experimentStatus": experimentStatus.value === undefined ? 4 : experimentStatus.value
			});
			if (experRes.code === 200) {
				list.value = experRes.data.rows;
				totalRows.value = experRes.data.totalRows;
			}
			tableLoading.value = false;
		}
		// 点击查询事件
		const handleSearch = () => {
			current.value = 1;
			searchExperiment();
		}

		// 点击收藏
		const handleCollectionClick = async (record) => {
			const res = await addMyCollection({
				ExperimentId: record.id,
				Title: record.title
			})
			if (res.code === 204 || res.code === 200) {
				message.success('收藏成功');
				record.isCollection = !record.isCollection;
				getCollectionList();
			}
			return
		}
		// 取消收藏
		const handleCollectionCancelClick = async (record) => {
			const res = await cancleMyCollection({
				ExperimentId: record.id
			})
			if (res.code === 204 || res.code === 200) {
				message.success('成功取消');
				record.isCollection = !record.isCollection;
				getCollectionList();
			}
		}

		// 正在进行授权的实验
		const authoringExperiment = ref({});
		// 授权后的回调
		const onAhtorization = () => {
			//searchExperiment();
		}

		// 记录本点击事件
		const handleNoteBookClick = async (notebook) => {
			resetSearch();
			if (notebook.noteBookId === activeNoteid.value) {
				return
			}
			else {
				activeNoteid.value = notebook.noteBookId;
				current.value = 1;
				searchExperiment();
			}
		}
		// 重置搜索条件
		const resetSearch = () => {
			dateRange.value = [];
			searchValue.value = '';
			conclusion.value = undefined;
			experimentStatus.value = undefined
		}
		/******************               实验列表 end                  ******************/

		/******************            左侧记录本 start              *****************/
		// 过滤字符串
		const noteFilterStr = ref('');
		// 左侧加载状态
		const leftLoading = ref(false);
		// 过滤后获得的记录本列表
		const notebookFilterList = computed(() => {
			if(noteFilterStr.value == '') {
				return []
			}
			return notebookList.value.filter((element) => {
				return element.noteBookName.toLocaleLowerCase().indexOf(noteFilterStr.value.toLocaleLowerCase()) !== -1;
			});

		})

		// 左侧实验记录点击事件
		const handleExperMenuItemClick = async (item) => {
			if (!item.openPermission) {
				message.error('无查看实验的权限');
				return;
			}
			if (item.readWritePermission) {
				if (!navigator.sendBeacon) {
					message.error('该浏览器不支持编辑实验，请更换别的浏览器')
					return;
				}
				const res = await getAntEditExperiment({ experimentId: item.experimentId });
				if (res.code === 200 && res.data.onlineEdit) {
					Modal.info({
						title: '提示',
						content: res.data.message
					})
				}
				else if (res.code === 200 && !res.data.onlineEdit) {
					const mode = item.readWritePermission ? 'edit' : 'preview';
					const href = context.root.$router.resolve({
						name: 'experiment',
						query: { mode: mode, id: item.experimentId, projectId: item.projectId }
					});
					window.open(href.href, '_blank')
				}
			}
			else {
				const mode = item.readWritePermission ? 'edit' : 'preview';
				const href = context.root.$router.resolve({
					name: 'experiment',
					query: { mode: mode, id: item.experimentId, projectId: item.projectId }
				});
				window.open(href.href, '_blank')
			}

		}

		// 实验记录编辑事件
		const handleExperimentClick = async (item) => {
			if (openExperimentList.value.indexOf(item.id) === -1) {
				openExperimentList.value.push(item.id);
			}
			if (item.experimentStatus === 1) {
				if (!navigator.sendBeacon) {
					message.error('该浏览器不支持编辑实验，请更换别的浏览器')
					return;
				}
				const res = await getAntEditExperiment({ experimentId: item.id });
				if (res.code === 200 && res.data.onlineEdit) {
					Modal.info({
						title: '提示',
						content: res.data.message
					})
				}
				else if (res.code === 200 && !res.data.onlineEdit) {
					const mode = 'edit';
					const href = context.root.$router.resolve({
						name: 'experiment',
						query: { mode: mode, id: item.id, projectId: item.projectId }
					});
					window.open(href.href, '_blank')
				}
			}
			else {
				const mode = 'preview';
				const href = context.root.$router.resolve({
					name: 'experiment',
					query: { mode: mode, id: item.id, projectId: item.projectId }
				});
				window.open(href.href, '_blank')
			}

		}

		const handleTest = () => {
			//console.log(window.location.origin)
			/* const formData = new FormData();
			formData.append('token', localStorage.getItem('Authorization'));
			formData.append('experimentId', 311533756907589);
			navigator.sendBeacon('/api/experiment/closeOnlineEditExperiment', formData); */
			//context.root.$store.dispatch('trigger/toggleAddExperiment')
			//navigator.sendBeacon('http://192.168.2.19:8080/api/experiment/anyEditExperiment?id=309390710218821')
			const formData = new FormData();
                formData.append('token', localStorage.getItem('Authorization'));
                formData.append('experimentId', 334431453876293);
                navigator.sendBeacon(`${process.env.VUE_APP_BASE_API}/experiment/closeOnlineEditExperiment`, formData);
			/* window.onunload = () => {
              if (mode.value === 'edit') {
                const formData = new FormData();
                formData.append('token', localStorage.getItem('Authorization'));
                formData.append('experimentId', id.value);
                navigator.sendBeacon(`${process.env.VUE_APP_BASE_API}/experiment/closeOnlineEditExperiment`, formData);
              }
            } */
		}
		/******************            左侧记录本 end                *****************/

		onMounted(async () => {
			leftLoading.value = true
			tableLoading.value = true;
			/* // 获取最近打开的实验
			const recentRes = await getRecentExperimentHistoryList();
			if (recentRes.code === 200) {
				recentExperimentList.value = recentRes.data;
			}
			else if (recentRes.code === 401) {
				return;
			}
			// 获取授权的实验
			const authorRes = await getAuthorizationExperiment();
			if (authorRes.code === 200) {
				authorizationExperimentList.value = authorRes.data;
			}
			// 获取我的收藏
			await getCollectionList(); */

			// 获取展示的列表头
			const displayRes = await getFieldList();
			if (displayRes.code === 200) {
				showColumns.value = displayRes.data
			}
			// 获取记录本列表
			const noteRes = await getNoteBookList();
			if (noteRes.code === 200) {
				notebookList.value = noteRes.data;
				activeNoteid.value = notebookList.value.length > 0 ? notebookList.value[0].noteBookId : null;
			}
			// 如果存在记录本则获取实验列表
			if (activeNoteid.value) {
				searchExperiment();
			}
			leftLoading.value = false;
			tableLoading.value = false;
		});
		// 获取结论颜色
		const getConclusionColors = (conclusion) => {
			if (conclusion === 1) {
				return '#52C41A';
			}
			else if (conclusion === 2) {
				return '#FF4D4F'
			}
			else if (conclusion === 3) {
				return `rgba(0, 0, 0, 0.45)`
			}
			else {
				return `rgba(0, 0, 0, 0.85)`
			}
		}
		// 获取结论值
		const getConclusionText = (conclusion) => {
			if (conclusion === 1) {
				return '成功';
			}
			else if (conclusion === 2) {
				return '失败'
			}
			else if (conclusion === 3) {
				return `停止`
			}
			else {
				return `——`
			}
		}
		// 流程状态
		const getExperimentStatuText = (statu) => {
			if (statu === 1) {
				return '编辑'
			}
			else if (statu === 2) {
				return '申请归档'
			}
			else if (statu === 3) {
				return '归档成功'
			}
		}
		/******************     流程状态查看 start        *****************/
		// 流程状态抽屉可见变量
		const statusFlowDrawerVisible = ref(false);
		// 流程选中id
		const statusFlowExperimentId = ref(null);
		// 查看流程状态点击事件
		const handleStatusFlowClick = (id) => {
			statusFlowExperimentId.value = id;
			statusFlowDrawerVisible.value = true;
		}
		/******************     流程状态查看 end          *****************/

		/******************       页面可见刷新  start         *****************/
		const visibility = useDocumentVisibility();
		watch(() => visibility.value, (newval) => {
			if (newval === 'visible') {
				if (activeNoteid.value) {
					searchExperiment();
				}
			}
		})
		/******************       页面可见刷新  end           *****************/
		const testHTML = `<div> <a href="/#/experiment?mode=edit&id=323572701405253&projectId=322749413220421" target="_blank">实验123 </a> </div>`;
		const testHandleClick = (e, p) => {
			const experimentRes = e.target.href.match(new RegExp(`id=(.*?)&projectId`));
			if (experimentRes) {

			}
		}

		/*******************      实验右击行 事件 start             ******************/
		const state = useTextSelection();
		const customRow = (record, index) => {
			return {
				on: {
					contextmenu: (e) => {
						if (state.text.value === '') {
							e.preventDefault();
							getExperimentContextMenu(e, record, openExperimentList);
						}
					}
				}
			}
		}

		/*******************      实验右击行 事件 end              ******************/

		/*******************      记录本删除成功回调               ******************/
		const handleNoteBookDelete = async () => {
			activeNoteid.value = null;
			// 获取记录本列表
			const noteRes = await getNoteBookList();
			if (noteRes.code === 200) {
				notebookList.value = noteRes.data;
				activeNoteid.value = notebookList.value.length > 0 ? notebookList.value[0].noteBookId : null;
			}
		}
		/****************************************************************************/

		/********************        删除实验                     ********************/
		// 实验删除操作点击
		const handleExperimentDeleteClick = (record) => {
			Modal.confirm({
                //content: `是否确认删除记录本\r\n  ${props.item.noteBookName} ？`,
                /*  content: <div>
                     是否确认删除记录本 <span style="color: #1890ff;"></span>
                 </div>, */
                content: h('div', {}, [h('span', {}, '是否确认删除实验 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`), ' ？']),
                onOk: async () => {
                    /* await new Promise((resolve) => {
                        setTimeout(() => {
                            resolve()
                        }, 3000)
                    }) */
                    const res = await cancelExperiment({
                        experimentId: record.id
                    });
                    if (res.success && (res.code === 200 || res.code === 204)) {
                        /* Modal.success({
                            content: h('div', {}, [h('span', {}, '成功删除 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`), ' ？'])
                        }) */
						Modal.success({
                            content: h('div', {}, [h('span', {}, '成功删除 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`), ' ？'])
                        })
                        searchExperiment();
                    }
                    else {
                        Modal.error({
                            content: res.message
                        })
                    }
                }
            })
		}
		/****************************************************************************/
		return {
			isLeftAsideCollapsed,
			toggoleCollapse,
			allColumns,
			showColumns,
			tableColumns,
			current,
			handlePageChange,
			list,
			tableHeight,
			authorizeModalVisible,
			handleAuthorizeButtonClick,
			getExperimentHref,
			goToExperimentRecord,
			makeReportModalVisible,
			handleMakeReportButtonClick,
			experimentCloneModalVisible,
			handleExperimentCloneButtonClick,
			addNoteBookModalVisible,
			notebookList,
			activeNoteid,
			totalRows,
			searchValue,
			conclusion,
			experimentStatus,
			recentExperimentList,
			authorizationExperimentList,
			collectionExperimentList,
			dateRange,
			handleSearch,
			addExperimentModalVisible,
			handleColoumnsCheckedToggle,
			handleCollectionClick,
			handleCollectionCancelClick,
			authoringExperiment,
			onAhtorization,
			handleNoteBookClick,
			cloneExperimentId,
			makeReportId,
			noteFilterStr,
			notebookFilterList,
			onClone,
			handleExperMenuItemClick,
			leftLoading,
			tableLoading,
			resetSearch,
			handleTest,
			handleExperimentClick,
			openExperimentList,
			getConclusionColors,
			getConclusionText,
			getExperimentStatuText,
			statusFlowDrawerVisible,
			statusFlowExperimentId,
			handleStatusFlowClick,
			openKeys,
			handleSubMenuTitleClick,
			getExperimentFlowStatuColor,
			testHTML,
			testHandleClick,
			customRow,
			state,
			subMenuLoading,
			handleNoteBookDelete,
			handleExperimentDeleteClick
		};
	},
	components: { AuthorizeModal, MakeReprotModal, ExperimentCloneModal, AddNewNotebookModal, AddNewExperimentModel, StatusFlowDrawer, NoteBookItem }
}
</script>

<style lang="less" scoped>
.exper-manage-ctn {
	margin-top: 2px;
	margin-bottom: 15px;
	display: flex;
	color: rgba(0, 0, 0, 0.85);

	.side-bar {
		height: calc(100vh - 65px);
		background: white;
		display: flex;
		box-shadow: @srims-primary-box-shadow;
		border-radius: 4px;

		.side-bar-left {
			width: 272px;
			height: 100%;
			padding: 24px 0px 24px 16px;

			.search-panel {
				display: flex;

				.ant-btn {
					margin-left: 8px;
				}
			}

			.exper-menus {
				margin-top: 34px;
				text-align: left;
				height: calc(100vh - 155px);
				overflow-y: auto;
				overflow-x: hidden;
				padding-bottom: 30px;

				/deep/ .exper-menu {
					.exper-menu-header {
						display: flex;
						align-items: center;

						.exper-menu-header-icon {
							fill:  @srims-primary-color;
							display: block;
							min-width: 16px;
						}

						.exper-menu-header-text {
							margin-left: 9px;
						}
					}

				 	/deep/ .exper-menu-item {
						display: flex;
						align-items: center;
						cursor: pointer;
						font-size: 14px;
						width: 100%;
						.menu-item-icon {
							min-width: 16px;
						}
						.exper-menu-item-text {
							margin-left: 8px;
							word-break: break-all;
							display: flex;
							white-space: wrap;
							width:180px;
						}

						transition: all .2s ease;
					}
				}
			}
		}

		.side-bar-left-collapsed {
			width: 0px !important;
			transform: translateX(-100%);
			overflow: hidden;
			opacity: 0;
			padding: 0;
			transition: all .3s ease-in-out .3s;
		}

		.side-bar-left-opened {
			transform: translateX(0);
			opacity: 1;
		}

		.collapse-bar {
			display: flex;
			height: 100%;
			align-items: center;
			justify-content: center;
			padding: 3px;
			background-color: #FFFFFF;
			cursor: pointer;

			.icon-container {
				position: relative;
				z-index: 100;
				cursor: pointer;

				&::before {
					content: '';
					position: absolute;
					width: 20px;
					height: 45px;
					color: white;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #f6F6F6;
					transform: perspective(.05em) rotateY(-2deg);
					z-index: 1;
				}

				.collapse-icon {
					position: absolute;
					z-index: 199;
					top: 17px;
					left: 5px;
					color: rgba(0, 0, 0, 0.85);
				}
			}

			&:hover {
				.collapse-icon {
					opacity: 0.8;
				}
			}

		}
	}

	.exper-manage-main {
		width: 0;
		flex-grow: 1;
		padding: 16px;
		flex: auto;
		min-width: 0;
		min-height: calc(100vh - 65px);
		

		.exper-manage-main-content {
			height: 100%;
			background: #ffffff;
			box-shadow: @srims-primary-box-shadow;
			border-radius: 4px;

			.exper-manage-main-content-header {
				margin-left: 16px;
				padding: 16px 16px 16px 0px;
				border-bottom: 1px solid #EBEBEB;
				font-size: 18px;
				text-align: left;
				font-weight: bold;
			}

			.exper-manage-search-panel {
				padding: 0px 16px;
				display: flex;
				flex-wrap: wrap;

				.search-option {
					width: 0px;
					min-width: 160px;
					flex-grow: 1;
					margin-top: 16px;
					text-align: left;

					.ant-select {
						width: 100%;
					}
				}

				.search-option+.search-option {
					margin-left: 16px;
				}

				.search-operation {
					white-space: nowrap;
					margin-left: 36px;
					margin-top: 16px;

					.search-btn {
						background:  @srims-primary-color;
					}

					.reset-btn {
						background: #eeeeee;
					}

					.ant-btn+.ant-btn {
						margin-left: 8px;
					}
				}
			}

			.exper-table-operations {
				text-align: left;
				margin-top: 24px;
				margin-left: 16px;
				display: flex;
				align-items: center;

				.table-operation {
					font-size: 24px;
					cursor: pointer;
				}

				.table-operation+.table-operation {
					margin-left: 16px;
				}
			}

			.table-ctn {
				min-height: calc(100vh - 330px);

				.open-link {
					color: #FF4D4F;

					&:hover {
						opacity: 0.8;
					}
				}

				.exper-table {
					margin-top: 16px;
					width: 99.99% !important;

					.statu-btn {
						&:hover {
							opacity: 0.8;
						}
					}
				}

				.operations {
					//padding: 16px;
					display: flex;

					.icon1 {
						font-size: 18px;
						cursor: pointer;
						fill: #4E5969;
						color: #4E5969;
					}

					.icon1+.icon1 {
						margin-left: 16px;
					}
				}
			}

			.default-pagination {
				text-align: center;
				margin-top: 24px;
				margin-bottom: 24px;

			}

			/deep/ .ant-table-thead {
				background: #F0F5FF;
			}

			/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
				margin: 0px;
			}

			/deep/ .ant-table-row {
				td {
					padding: 7px;
				}
			}
		}
	}
}
</style>

<style lang="less" scoped>
/deep/ .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: none;
}

/deep/ .ant-menu-item {
	background: transparent;

	&:hover {
		background:  @srims-primary-color;
		color: white;
		fill: white;
	}
}

/deep/ .ant-menu-item-selected {
	color: rgba(0, 0, 0, 0.85);
	background-color: white !important;

	&:hover {
		background:  @srims-primary-color !important;
		color: white;
		fill: white;
	}

	&::after {
		border-right: none;
	}
}

/deep/ .ant-menu-submenu-selected {
	.exper-menu-header-text {
		color:  @srims-primary-color;
	}
}

/deep/ .ant-menu-submenu {
	.ant-menu-submenu-title {
		padding-left: 0px !important;
		padding-right: 0px !important;

		.ant-menu-submenu-arrow {
			right: 0px;
		}
	}

	&:hover {
		.exper-menu-header-text {
			color:  @srims-primary-color;
		}
	}
}

/deep/ .notebook-item.ant-menu-item-selected {
	background:  @srims-primary-color !important;
	fill: white;
	color: #FFFFFF;
}

/deep/ .notebook-item.ant-menu-item {
	height: fit-content;

	.normal-notebook {
		display: flex;
		padding: 8px 5px 8px 20px;

		.menu-item-icon {
			min-width: 16px;
			transform: translateY(4px);
		}

		.exper-menu-item-text {
			word-break: break-all;
			text-align: left;
			display: flex;
			white-space: wrap;
			display: block;
			line-height: 22px;
			margin-left: 8px;
		}
	}

	.active-notebook {
		background:  @srims-primary-color;
		color: #ffffff;
		fill: #ffffff;
	}
}

.notebook-item-ctn + .notebook-item-ctn {
	margin-top: 10px;
}

.exper-menu-content {
	/deep/ li {
		list-style: none;
	}
	/deep/ .ant-menu-submenu-title {
		display: none;
	}
}
</style>