import globalVueThis from '../main';
import { message, Modal } from 'ant-design-vue';
import { getAntEditExperiment } from '../api/experiment'
const getMenuItems = (record, openExperimentList) => {
    if (record.experimentStatus === 1) {
        return [
            {
                label: '查看',
                onClick: () => {
                    if(openExperimentList.value.indexOf(record.id) === -1) {
                        openExperimentList.value.push(record.id)
                    }
                    const href = globalVueThis.$router.resolve({ name: 'experiment', query: { mode: 'preview', id: record.id, projectId: record.projectId } });
                    window.open(href.href, '_blank')
                }
            },
            {
                label: '编辑',
                onClick: async () => {
                    if (!navigator.sendBeacon) {
                        message.error('该浏览器不支持编辑实验，请更换别的浏览器')
                        return;
                    }
                    if(openExperimentList.value.indexOf(record.id) === -1) {
                        openExperimentList.value.push(record.id)
                    }
                    const res = await getAntEditExperiment({ experimentId: record.id });
                    if (res.code === 200 && res.data.onlineEdit) {
                        Modal.info({
                            title: '提示',
                            content: res.data.message,
                            cancelText: ''
                        })
                    }
                    else if (res.code === 200 && !res.data.onlineEdit) {
                        const href = globalVueThis.$router.resolve({ name: 'experiment', query: { mode: 'edit', id: record.id, projectId: record.projectId } });
                        window.open(href.href, '_blank')
                    }
                }
            }
        ]
    }
    return [
        {
            label: '查看',
            onClick: async () => {
                if(openExperimentList.value.indexOf(record.id) === -1) {
                    openExperimentList.value.push(record.id)
                }
                const href = globalVueThis.$router.resolve({ name: 'experiment', query: { mode: 'preview', id: record.id, projectId: record.projectId } });
                window.open(href.href, '_blank')
            }
        }
    ]
}

export const getExperimentContextMenu = (event, record,  openExperimentList) => {
    globalVueThis.$contextmenu({
        items: getMenuItems(record,  openExperimentList),
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 133, // 菜单样式 z-index
        minWidth: 230 // 主菜单最小宽度
    });
    return false;
}